body, button {
  color: #2f2438;
  font-family: Open Sans, sans-serif;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  font-family: Fjalla One, sans-serif;
  font-weight: 500;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.SocialAuthGoogle:not([disabled]):hover .SocialAuthGoogleBody {
  background-color: #3b77dc;
}

.SocialAuthGoogle:disabled .SocialAuthGoogleBody {
  background-color: #e6e6e6e6;
}

.sidebarItem:hover .sidebarAdornment {
  color: #e2bab0;
}

.privacy-policy {
  padding: 2rem;
}

.privacy-policy p {
  margin-left: 2rem;
}

.cloud {
  opacity: 0;
  transition: all .157s;
}

.show {
  opacity: 1;
}

input[type="date"] {
  font-family: Open Sans, sans-serif;
}

input[data-test-id="selectInput"]:focus {
  box-shadow: none;
}

.unbr--date-input::-webkit-inner-spin-button {
  filter: invert();
}

.unbr--date-input::-webkit-calendar-picker-indicator {
  filter: invert();
}

.table__body {
  background-color: #fffb;
  border-radius: .6rem;
  width: 100%;
  max-height: calc(89% - .8rem);
  margin: .8rem auto;
  overflow: auto;
}

table, th, td {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  padding: 1rem;
}

thead th {
  position: sticky;
  top: 0;
  left: 0;
}

thead tr {
  border-bottom: 1px solid #000;
}

.PublicHome--clip {
  clip-path: circle(125% at 50% -83%);
}

.PublicHome--footer__clip {
  clip-path: circle(180% at 50% 332%);
}

/*# sourceMappingURL=index.c8ddf0c0.css.map */
