body,
button {
  font-family: "Open Sans", sans-serif;
  color: #2f2438;
}

body {
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Fjalla One", sans-serif;
  font-weight: 500;
  margin: 0px;
}

ul {
  padding: 0px;
}

li {
  list-style-type: none;
}

.SocialAuthGoogle:not([disabled]):hover {
  .SocialAuthGoogleBody {
    background-color: #3b77dc;
  }
}

.SocialAuthGoogle:disabled {
  .SocialAuthGoogleBody {
    background-color: #e6e6e6e6;
  }
}

.sidebarItem:hover {
  .sidebarAdornment {
    color: #e2bab0;
  }
}

.privacy-policy {
  padding: 2rem;

  p {
    margin-left: 2rem;
  }
}

.cloud {
  opacity: 0;
  transition: 157ms;
}

.show {
  opacity: 1;
}

input[type="date"] {
  font-family: "Open Sans", sans-serif;
}
input[data-test-id="selectInput"]:focus {
  box-shadow: none;
}

// Components
@import "./app/Components/Date/date.scss";
@import "./app/Pages/Admin/styles.scss";
@import "./app/Pages/Public/styles.scss";
