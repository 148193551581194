.table__body {
  width: 100%;
  max-height: calc(89% - 0.8rem);
  background-color: #fffb;
  margin: 0.8rem auto;
  border-radius: 0.6rem;
  overflow: auto;
}

table,
th,
td {
  padding: 1rem;
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

thead th {
  position: sticky;
  top: 0;
  left: 0;
}

thead tr {
  border-bottom: 1px solid black;
}
